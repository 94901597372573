import { deleteDataById, getData, getPaginatedData, insertData, patchData } from './commonService';

export default class EmployeeOvertimeService {


    #defaultUrl = '/api/EmployeeOvertime';
    #defaultResourceName = 'Fazla Mesai';

    async insertEmployeeOvertime(dataItem) {
        const createData = {
            "projectId": dataItem.projectId,
            "employeeId": dataItem.employeeId,
            "overtimeDate": dataItem.overtimeDate,
            "hour": dataItem.hour,
            "type": dataItem.type,
            "isActive": true,
            "comment": dataItem.comment,
            "totalPrice": dataItem.totalPrice,
            "hourlyPrice": dataItem.hourlyPrice,
            "projectEmployeeId": dataItem.projectEmployeeId,
            "managerUserId": dataItem.managerUserId
        }

        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async updateEmployeeOvertime(employeeId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "overtimeDate", "value": dataItem.overtimeDate},
            {"op": "replace", "path": "hour", "value": dataItem.hour},
            {"op": "replace", "path": "type", "value": dataItem.type},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
            {"op": "replace", "path": "comment", "value": dataItem.comment},
        ];
        return await patchData(this.#defaultUrl, employeeId, patchDocument, this.#defaultResourceName);

    }

    async getEmployeeOvertimes(projectEmployeeId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectEmployeeId=' + projectEmployeeId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectOvertimes(projectId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectOvertimeReport(projectId, startDate, endDate) {
        return await getData(this.#defaultUrl + '/report?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }



    async deleteEmployeeOvertimes(id) {
        return await deleteDataById(this.#defaultUrl, id, this.#defaultResourceName);
    }

    async checkOvertimeDate(projectEmployeeId, overtimeDate) {
        let response = await getData(this.#defaultUrl + '/check?projectEmployeeId=' + projectEmployeeId + '&overtimeDate=' + overtimeDate.valueOf());
        return response.data;
    }
}