<template>
    <AdminToast />
    <ConfirmDialog></ConfirmDialog>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='employee'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Mesai Ekleme</h3>

					<p class='text-lg'>
                        <b>Toplam Mesai Saati:</b> Personelinizin yapmış olduğu toplam mesai saatini girin.<br>
                        <br>
                        <b>Mesai Tarihi:</b> Mesainin yapıldığı tarihi girin.<br>
                        <br>
                        <b>Mesai Tipi:</b> Mesai tipini seçin. <b>Günlük</b> Mesai seçimi mesai tutarında çarpan olmayan hesaplamadır.
                         Örneğin haftalık tatilini yapmayan personel için Günlük seçimi yapılıp mesai süresinide 7.5 saat girdiğinizde 30 günlük maaş hesaplamasına göre günlük ücret ortaya çıkar.<br>
                        <br>
                        Normal mesai ücreti saatlik ücretin bir buçuk katı olup Tatil Günü mesaisi saatlik ücretin iki katıdır. <br>
                        <br>
                        Personel saatlik ücreti hesaplaması Toplam Maaş / 30 / 7.5 = Saatlik Ücret olarak hesaplanır.<br>
                        <br>
                        Girmiş olduğunuz verilere göre personelin saatlik ücreti formda otomatik hesaplanır.<br>
                        <br>
                        <span class='text-red-500'>Maaş hesaplaması yapmadan önce mesai verilerinin girilmesi gerekir. Maaş hesaplaması yapıldıktan sonra mesai silme veya ekleme o çalışma ayı için yapılamaz. </span>
					</p>
				</Sidebar>

				</span>
            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId'
                                           :project-employee-id='projectEmployeeId'></project-employee-tab-menu>

                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Toplam Mesai Saati<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='hour' type='text' autocomplete='off' v-model='dataItem.hour'
                                       @focusout='typeChanged' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Mesai Tarihi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='overtimeDate' dateFormat='dd.mm.yy' autocomplete='off' :showIcon='true'
                                      :showButtonBar='true' v-model='dataItem.overtimeDate'
                                      @date-select='dateSelected'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Mesai Tipi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='type' v-model='dataItem.type' :options='typeList' optionLabel='label'
                                      option-value='value' placeholder='Seçim Yapın' @change='typeChanged'></Dropdown>
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='dataItem.type != ""'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Mesai Ücreti (saatlik)<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='overtimeHourlyPrice' autocomplete='off' type='text'
                                       v-model='overtimeHourlyPrice' readonly='true' />
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='dataItem.type != ""'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Mesai Ücreti<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='overtimeTotalPrice' autocomplete='off' type='text'
                                       v-model='overtimeTotalPrice' readonly='true' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Mesai Nedeni<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='comment' type='text' autocomplete='off' v-model='dataItem.comment' />
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='save' label='Kaydet' icon='pi pi-save' @click='save'
                                    v-bind:disabled='isProcessing'></Button>
                        </div>
                    </div>

                    <div class='mt-5'>

                        <Toolbar class='mb-3 p-3'>
                            <template v-slot:start>
                                <div class='my-0'>
                                    <h5 class='mb-0'>
                                        <Calendar id='selectedMonth' view='month' dateFormat='MM - yy' :showIcon='true'
                                                  :showButtonBar='true' v-model='selectedSalaryDate'
                                                  @date-select='salaryDateChanged'></Calendar>
                                    </h5>
                                </div>
                            </template>
                        </Toolbar>

                        <DataTable :value='overtimeList' class='p-datatable-gridlines' :rows='10' dataKey='id'
                                   :rowHover='true' :paginator='true' :loading='loadingIndicator'
                                   responsiveLayout='scroll'>

                            <template #empty>
                                Mesai Kaydı Bulunamadı.
                            </template>

                            <template #loading>
                                Projedeki Mesailer yükleniyor. Lütfen bekleyin.
                            </template>

                            <Column field='overtimeDate' header='Tarih' style='width:9rem'>
                                <template #body='slotProps'>
                                    {{ formatDate(slotProps.data.overtimeDate) }}
                                </template>
                            </Column>

                            <Column field='hour' header='Toplam Mesai Saati' bodyClass='text-center' style='width:9rem'>
                            </Column>

                            <Column field='hourlyPrice' header='Saatlik Ücret' bodyClass='text-center'
                                    style='width:9rem'>
                                <template #body='{data}'>
                                    {{ formatPrice(data.hourlyPrice) }} &#8378;
                                </template>
                            </Column>

                            <Column field='totalPrice' header='Toplam Ücret' bodyClass='text-center' style='width:9rem'>
                                <template #body='{data}'>
                                    {{ formatPrice(data.totalPrice) }} &#8378;
                                </template>
                            </Column>

                            <Column field='type' header='Mesai Tipi' style='width:9rem'>
                            </Column>

                            <Column header='Açıklama' filterField='comment'>
                                <template #body='{data}'>
                                    {{ data.comment }}
                                </template>
                            </Column>

                            <Column header='Sil' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                                <template #body='slotProps'>
                                    <Button icon='pi pi-trash' class='p-button-rounded p-button-primary'
                                            @click='deleteOvertime(slotProps.data)' />
                                </template>
                            </Column>

                        </DataTable>

                    </div>
                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage, showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import moment from 'moment';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import EmployeeOvertimeService from '../../services/employeOvertimeService';
import {
    checkEmployeeSalaryCalculated, checkEmployeeSalaryChanged,
    checkEmployeeStillActive,
    getEmployee,
    getProjectEmployee,
} from '../common/commonFunctions';
import {
    checkActiveProjectIdIsValid,
    getActiveProjectId,
    getUserId,
    normalizeDate,
} from '../common/commonConstantFunctions';
import SmsService from '@/services/smsService';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _projectEmployeeService: null,
    _employeeOvertimeService: null,
    _smsService:null,

    created() {
        this._smsService = new SmsService();
        this.selectedSalaryDate = moment().toDate();
        this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
        this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
        this._employeeService = new EmployeeService();
        this._employeeOvertimeService = new EmployeeOvertimeService();
        this._projectEmployeeService = new ProjectEmployeeService();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
        this.projectId = getActiveProjectId();
    },

    async mounted() {
        this.employee = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        await this.getEmployeeOvertimeList();
    },
    data() {

        return {
            isProcessing: false,
            projectEmployeeId: 0,
            selectedSalaryDate: null,
            year: 1900,
            month: 1,
            loadingIndicator: false,
            employeeId: 0,
            overtimeList: [],
            dataItem: {
                projectId: 0,
                employeeId: 0,
                hour: 0,
                overtimeDate: '',
                type: 0,
                isActive: true,
                comment: '',
                totalPrice: 0,
                hourlyPrice: 0,
            },
            projectEmployee: {
                officialSalary: 0,
            },
            overtimeHourlyPrice: 0,
            overtimeTotalPrice: 0,
            employee: {},
            typeList: [
                {
                    label: 'Günlük',
                    value: 'Günlük',
                },
                {
                    label: 'Normal',
                    value: 'Normal',
                },
                {
                    label: 'Tatil Günü',
                    value: 'Tatil Günü',
                },
            ],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async dateSelected() {
            await this.checkOvertimeDayAvailable();
        },
        async salaryDateChanged() {
            this.loadingIndicator = true;
            this.month = moment(this.selectedSalaryDate).toDate().getMonth() + 1;
            this.year = moment(this.selectedSalaryDate).toDate().getFullYear();
            await this.getEmployeeOvertimeList();
        },
        typeChanged() {
            if (this.dataItem.hour <= 0) {
                showValidationMessage(this, 'Lütfen Önce Saat Girin');
                return;
            }
            let multiplier = 1.5;
            if (this.dataItem.type == 'Tatil Günü') {
                multiplier = 2;
            } else if (this.dataItem.type == 'Günlük') {
                multiplier = 1;
            }
            let dailyPrice = Number(this.projectEmployee.officialSalary + this.projectEmployee.unOfficialSalary) / 30;
            let hourlyPrice = dailyPrice / 7.5;
            let overtimeHourlyPrice = (hourlyPrice * multiplier).toFixed(2);
            this.overtimeHourlyPrice = overtimeHourlyPrice;
            this.overtimeTotalPrice = (overtimeHourlyPrice * Number(this.dataItem.hour)).toFixed(0);
            this.dataItem.totalPrice = this.overtimeTotalPrice;
            this.dataItem.hourlyPrice = this.overtimeHourlyPrice;

        },
        resetOvertimeForm() {
            this.dataItem.totalPrice = 0;
            this.dataItem.hourlyPrice = 0;
            this.dataItem.type = '';
            this.dataItem.overtimeDate = null;
            this.dataItem.hour = 0;
            this.dataItem.comment = '';
            this.overtimeHourlyPrice = 0;
            this.overtimeTotalPrice = 0;
        },
        async save() {
            this.isProcessing = true;
            if (await this.validateForm()) {
                this.buildOvertimeObject();
                const insertResponse = await this._employeeOvertimeService.insertEmployeeOvertime(this.dataItem);
                if (insertResponse.isSuccess) {
                    await this.getEmployeeOvertimeList();
                    await this._smsService.sendEmployeeNewOvertimeMessage(this.projectEmployeeId, insertResponse.data);
                    this.resetOvertimeForm();
                    showSuccessMessage(this, 'Çalışan Güncelleme Başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan Güncellemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        buildOvertimeObject() {
            this.dataItem.projectId = this.projectEmployee.projectId;
            this.dataItem.managerUserId = getUserId();
            this.dataItem.overtimeDate = normalizeDate(this.dataItem.overtimeDate);
            this.dataItem.employeeId = this.employeeId;
            this.dataItem.projectEmployeeId = this.projectEmployeeId;
        },
        async validateForm() {

            if (!checkEmployeeSalaryChanged(this, this.projectEmployee)) {
                return false;
            }

            if (await checkEmployeeStillActive(this, this.projectEmployeeId) == false) {
                return false;
            }

            if (this.dataItem.overtimeDate == '') {
                showValidationMessage(this, 'Mesai Tarihi Seçilmelidir');
                return false;
            }

            if (await checkEmployeeSalaryCalculated(this, this.dataItem.overtimeDate, this.projectEmployeeId) == true) {
                return false;
            }

            if (this.dataItem.hour == '') {
                showValidationMessage(this, 'Mesai Süresi Girilmelidir.');
                return false;
            }

            if (this.dataItem.type == '') {
                showValidationMessage(this, 'Mesai Tipini Seçilmelidir');
                return false;
            }

            if (this.dataItem.comment == '') {
                showValidationMessage(this, 'Mesai Nedeni Girilmelidir');
                return false;
            }


            if ((await this.checkOvertimeDayAvailable()) == false) {
                return false;
            }


            return true;
        },

        async getEmployeeOvertimeList() {
            this.loadingIndicator = true;

            let startDate = normalizeDate(moment(this.selectedSalaryDate).startOf('month').toDate());
            let endDate = normalizeDate(moment(this.selectedSalaryDate).endOf('month').toDate());
            let overtimeResponse = await this._employeeOvertimeService.getEmployeeOvertimes(this.projectEmployeeId, startDate, endDate);
            if (overtimeResponse.isSuccess) {
                this.overtimeList = overtimeResponse.data;
            }
            this.loadingIndicator = false;
        },
        async deleteOvertime(item) {
            if (await checkEmployeeSalaryCalculated(this, item.overtimeDate, this.projectEmployeeId) === true) {
                return;
            }
            this.$confirm.require({
                message: 'Fazla Mesai Kaydını Silmek İstediğinize Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let deleteResponse = await this._employeeOvertimeService.deleteEmployeeOvertimes(item.id);
                    if (deleteResponse.isSuccess) {
                        showSuccessMessage(this, 'Silme İşlemi Başarılı');
                        await this.getEmployeeOvertimeList();
                    } else {
                        showErrorMessage(this, 'Silme İşlemi Sırasında Hata Oluştu');
                    }
                },
            });

        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        async checkOvertimeDayAvailable() {
            let checkResponse = await this._employeeOvertimeService.checkOvertimeDate(this.projectEmployeeId, this.dataItem.overtimeDate);
            if (checkResponse == true) {
                showValidationMessage(this, 'Bu Tarihde Zaten Bir Mesai Tanımlı');
                return false;
            }
            return true;
        },
    },
};

</script>


<style scoped>

</style>
